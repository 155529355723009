import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { Flex, Box } from 'theme-ui'


import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'

import { normalizeBlockContentNodes } from '@blocks-helpers'
import Blog3 from '@solid-ui-blocks/Blog/Block03'
import httpService from '../../service/httpService';
import loadsGif from '../assets/loads.gif'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'
import SatinAlmaFrm from '@solid-ui-blocks/Siparis/SatinAlma'
import SatinAlmaDetay from '@solid-ui-blocks/Siparis/SatinAlmaDetay'


import Tabs from '@solid-ui-components/Tabs'


const SatinAlmaPage = props => {


  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  const [indId, setindId] = useState("");
  const [sipId, setsipId] = useState("");

  const [ldg, setLdg] = useState(false);
  const [filterTip, setfilterTip] = useState(0);
  const [tbHndl, settbHndl] = useState(0);


  useState(() => {
    console.log("asdasd");

    if (typeof window !== 'undefined') {

      if (window.location.search.includes("S=")) {
        setfilterTip(0);

        setsipId(window.location.search.replace("?S=", ""));
      } else if (window.location.search.includes("B=")) {
        setfilterTip(1);
        hdndleTab(1);
        setsipId(window.location.search.replace("?B=", ""));

      }else if (window.location.search.includes("T=")) {
        setfilterTip(2);
        hdndleTab(1);
        hdndleTab(2);
        setsipId(window.location.search.replace("?T=", ""));

      }

      setLdg(true);
    }
  }, []);

function hdndleTab(tbInd) {
  settbHndl(tbInd);
}


  return (
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Satın Alma İşlemleri' />
      <Divider space='5' />
      {ldg == false ? <div className='dv-l'>
        <Divider space="3" />
        <img src={loadsGif} alt="die-cut order" style={{ width: "200px", objectFit: "contain" }} />
        <Divider space="5" />
      </div> :
        <>
          <Box>
            <div className="dash-tab">
              <Tabs  customSetHandleNext={tbHndl} sx={{ flex: 1, p: 20, paddingBottom: 20 }} tabs={["Satınalma Bekleyenler", "Sipariş Geçilenler", "Teslim Alınanlar"]} onChange=
                {e => {
                  setfilterTip(e);
                  // GetSatinAlmaList(e);
                }}
                variant='pill'>

                <div> </div>
                <div> </div>
                <div> </div>

              </Tabs>
            </div>

          </Box>
          <SatinAlmaFrm content={{ SiparisId: sipId, FilterTip: filterTip }} ></SatinAlmaFrm>

          {/* {filterTip == 0 && <SatinAlmaFrm content={{ SiparisId: sipId, FilterTip: filterTip }} ></SatinAlmaFrm>

          }
          {filterTip > 0 && <SatinAlmaDetay content={{ SiparisId: sipId, FilterTip: filterTip }} ></SatinAlmaDetay>

          } */}
        </>

      }
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <CanliDestek></CanliDestek>
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageSatinAlmaPage { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`


export default SatinAlmaPage
