import React, { useEffect, useContext, useState } from 'react'
import { Container, Flex, Box, Heading, Radio, IconButton, css, Text, Select, Button } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import TeklifTextField from '@solid-ui-components/ContentForm/TeklifTextField'
import FormInput3 from '@solid-ui-components/ContentForm/FormInput3'

import useForm from '@helpers/useForm'
import { FormContext } from '@solid-ui-components/ContentForm'
import httpService from '../../../../../../site/src/service/httpService'
import httpServiceToken from '../../../../../../site/src/service/httpServiceToken'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import ReactModal from 'react-modal'
import { FaRegStar, FaPlus, FaHandHoldingHeart, FaHandHoldingMedical } from 'react-icons/fa'
import Slider from '@solid-ui-blocks/Slider/Block01'
import loadingGif from '../../../assets/model-cizim-kutu-tasarimi2.gif'
import parse from 'html-react-parser'
import { setKtTokenSifre, getKtLogin, logOut, getKtToken } from '../../../../../../site/src/service/auth'
import loadsGif from '../../../assets/loads.gif'
import btnloading from '../../../assets/btnloading.gif'

import loadsBeyazGif from '../../../assets/loadsBeyaz.gif'
import indirPng from '../../../assets/online-paskage-pdf-die-cut.png'
import favoriPng from '../../../assets/favori-bicak-izi-pdf.png'
import noSearchPng from '../../../assets/no-search-die-cutting.png'

import { ModalContext } from '@solid-ui-components/Modal'
// import indirmeCss from './_bicakizi.css' //style={indirmeCss}
import Tabs from '@solid-ui-components/Tabs'
// import { Tab } from 'react-tabs'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from 'react-accessible-accordion'
import Icon from '@solid-ui-components/ContentIcon'
import Reveal from '@solid-ui-components/Reveal'
import toast, { Toaster } from 'react-hot-toast'



const styles = {
    accordion: {
        '.accordion__button': {
            cursor: `pointer`,
            position: `relative`,
            outline: `none`,
            transition: `all 0.15s ease-in`
        },
        '.accordion__button:hover': {
            opacity: 0.75,
            '&::before': {
                borderColor: `beta`
            }
        },
        '.accordion__button::before': {
            content: `' '`,
            width: `10px`,
            height: `10px`,
            marginRight: `12px`,
            borderBottom: `3px solid currentColor`,
            borderLeft: `3px solid currentColor`,
            position: `absolute`,
            right: 4,
            top: `50%`,
            transform: `translate(0, -50%) rotate(45deg)`,
            transition: `all 0.15s ease-in`
        },
        '.accordion__button[aria-expanded="true"]::before, .accordion__button[aria-selected="true"]::before': {
            transform: `translate(0, -50%) rotate(-45deg)`,
            transition: `transform 0.35s ease-in`
        },
        '[hidden]': {
            display: `none`
        },
        '.accordion__panel': {
            animation: `fadeIn 0.25s ease-in`
        },
        '@keyframes fadeIn': {
            '0%': {
                opacity: 0.5,
                transform: 'translate(0, -15%)'
            },
            '100%': {
                opacity: 1,
                transform: 'translate(0, 0%)'
            }
        }
    }
    ,
    listItem: {
        alignItems: `stretch`,
        justifyContent: `flex-start`
    },
    line: {
        position: `absolute`,
        bottom: `10px`,
        left: `50%`,
        width: `1px`,
        height: t => `calc(100% - ${t.sizes.icon.md + 20}px)`,
        transform: `translateX(-50%)`,
        zIndex: 1,
        bg: `omega`
    },
    number: {
        color: `white`,
        fontWeight: `body`,
        borderRadius: `full`,
        bg: `alpha`,
        size: `icon.md`,
        textAlign: `center`,
        p: 2,
        mb: 0
    },

    btnResimDegistir: {
        flex: [`100%`, null, null, 1],
        minWidth: 75,
        cursor: `pointer`,
        textAlign: `center`,
        pl: 3,
        pr: 3,
        m: 2,
        borderRadius: '0.5rem',
        backgroundColor: "white",
        border: "0.8px dotted red",
        fontSize: "0.87rem",

    },
    btnIslemler: {
        flex: [`100%`, null, null, 1],
        minWidth: 75,
        cursor: `pointer`,
        textAlign: `center`,
        pl: 3,
        pr: 3,
        m: 2,
        borderRadius: '0.5rem',
        backgroundColor: "#87f9bb",
        border: "0.8px dotted #204c07",
        fontSize: "0.87rem",
        color: '#204c07'
    },
    btnIslemSil: {
        flex: [`100%`, null, null, 1],
        minWidth: 75,
        cursor: `pointer`,
        textAlign: `center`,
        pl: 3,
        pr: 3,
        m: 2,
        borderRadius: '0.5rem',
        backgroundColor: "#FF6666",
        border: "0.8px dotted #FCAEAE",
        fontSize: "0.87rem",
        color: 'white'
    }
}

const SatinAlmaDetay = ({ content: { SiparisId, FilterTip }, ...props }) => {

    const formId = "frmSiparis"
    const { handleSubmit, submitting, success } = useForm()
    const { formValues, setFormValues } = useContext(FormContext)
    const { setActiveModal } = useContext(ModalContext)
    const [ldg, setLdg] = useState(false);
    const [btnLdg, setbtnLdg] = useState(true);



    const [siparis, setsiparis] = useState(null);
    // const [teklif, setTeklif] = useState(null);
    // const [anaLst, setanaLst] = useState(null);
    const [lst, setLst] = useState(null);
    // const [selectLst, setselectLst] = useState([]);

    // let selectLst = [];

    const [toplamBilgi, settoplamBilgi] = useState("");

    // const [filterTip, setfilterTip] = useState(0);


    const [firtProcess, setfirtProcess] = useState("1");

    useEffect(async () => {
        if (typeof window !== 'undefined' && firtProcess == "1") {
            const ktLoginMiii = await getKtLogin();
            if (ktLoginMiii == true) {
              
                GetSiparis();
                GetSatinAlmaList(FilterTip);
                setfirtProcess("2");
            }
            else {
                window.location.assign("/");
            }
        }
        setLdg(true);
    }, [firtProcess]);

    useEffect(async () => {
        GetSatinAlmaList(FilterTip);
    }, [FilterTip]);


    async function GetSatinAlmaList(tip) {

        httpServiceToken.post("Locked/GecilenSatinAlmaList", { params: { Tip: tip } }).then((res) => {
            // console.log(res);
            if (res.DogruMu) {

                setLst(res.Data);

            } else {
                setLst(null);
                // setanaLst(null);

            }
        }).catch((err) => {
            console.log(err);
        });



    }

    async function GetSiparis() {
        if (SiparisId && firtProcess == "1") {
            httpServiceToken.post("Locked/GetSiparis", { params: SiparisId }).then((res) => {
                // console.log(res);
                if (res.DogruMu) {
                    setsiparis(res.Data);
                  

                    // setTeklif(res.Data.Teklif);
                } else {

                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }




    return (
        <Container>
            <Toaster />
            {ldg == false ? <div className='dv-l'>
                <Divider space="3" />
                <img src={loadsGif} alt="online box die cutting loading" style={{ width: "200px", objectFit: "contain" }} />
                <Divider space="5" />
            </div> :
                <form

                    method='POST'
                >
                    <Box>

                        <Box sx={{ textAlign: `center` }} >
                            <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -1 }}>
                                <Box sx={{
                                    flex: [`100%`, null, null, 1],
                                    width: '100%',
                                    px: [2, 2],
                                    py: [0, 1]
                                }}>
                                    <table style={{
                                        width: '100%',
                                        paddingBottom: "20px"
                                    }}>
                                        <tbody>
                                            <tr>
                                                <td style={{
                                                    width: '25%'

                                                }} >
                                                    {siparis && <Box
                                                        sx={styles.btnIslemler}
                                                        onClick=
                                                        {e => {
                                                            window.location.assign("/siparis-formu?I=" + siparis.Id)

                                                        }}>
                                                        Siparişe Geri Git
                                                    </Box>}
                                                </td>
                                                <td style={{
                                                    width: '25%',
                                                }} >

                                                </td>
                                                <td style={{
                                                    width: '25%',
                                                }} >

                                                </td>
                                                <td style={{
                                                    width: '25%',
                                                }} >
                                                    {/* <Box
                                    sx={styles.btnIslemler}
                                    onClick=
                                    {e => {

                                    }}>
                                    Yeni Fiyatlarla Teklif Kopyala
                                </Box> */}
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>

                                    <Box sx={styles.accordion}>
                                        {lst && <Accordion allowMultipleExpanded={true} >
                                            {lst?.map(({ tid, tadi, KlmSayisi, Kalemler, NotVarMi, RilVarMi, DalgaBaslik }, index) => (
                                                <Container

                                                    key={`item-${index}`}
                                                    variant='cards.primary'
                                                    sx={{ '& + &': { mt: 3 } }}
                                                >
                                                    <AccordionItem dangerouslySetExpanded={true}  >
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                <Flex sx={{ alignItems: `center` }} p='3'>
                                                                    <Icon size='xs' mr='3' p='2' round />
                                                                    {/* <ContentText content= mb='0' pr='5' /> */}
                                                                    {tadi}
                                                                </Flex>
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <Box p='2'>
                                                                <table border="1px" style={{ width: '100%', borderColor: "#fdfdfd", borderCollapse: 'collapse' }}>
                                                                    <tbody>
                                                                        {Kalemler.length > 0 && <tr>
                                                                            <th>Satın Alma No</th>
                                                                            <th style={{ fontSize: '12px', padding: '0.2rem' }}>Müşteri</th>
                                                                            <th style={{ fontSize: '12px', padding: '0.2rem' }}>Sipariş</th>
                                                                            <th style={{ fontSize: '12px', padding: '0.2rem' }}>EnxBoy</th>
                                                                            {RilVarMi == true && <th style={{ fontSize: '12px', padding: '0.2rem' }}>Riller</th>}
                                                                            <th style={{ fontSize: '12px', padding: '0.2rem' }}>{DalgaBaslik}</th>
                                                                            <th style={{ fontSize: '12px', padding: '0.2rem' }}>Kalite</th>
                                                                            <th style={{ fontSize: '12px', padding: '0.2rem' }}>Birim Ağırlık</th>
                                                                            <th style={{ fontSize: '12px', padding: '0.2rem' }}>Toplam Ağırlık</th>
                                                                            <th style={{ fontSize: '12px', padding: '0.2rem' }}>Adet</th>
                                                                            <th style={{ fontSize: '12px', padding: '0.2rem' }}>Birim Fiyat</th>
                                                                            <th style={{ fontSize: '12px', padding: '0.2rem' }}>Tutar</th>
                                                                            {NotVarMi == true && <th style={{ fontSize: '12px', padding: '0.2rem' }}>Dikkat</th>}
                                                                        </tr>
                                                                        }
                                                                        {Kalemler.map(({ SatinAlmaNo, Teklif, Siparis, En, Boy, Riller, DalgaTipiText, TedarikciKalitesi, BirimAgirlik, ToplamAgirlik, Adet, BirimFiyat, Tutar, DegisiklikNotu, SecildiMi }, ind2) => {
                                                                            let klmId = Kalemler[ind2].Id;
                                                                            var adClas = "";
                                                                            if (siparis && siparis.SiparisNo == Siparis.SiparisNo) {
                                                                                adClas = " satin-alma-bu-siparis";
                                                                            }
                                                                            return (
                                                                                <tr key={`item-${ind2}`} className={"noselect-satin-alma" + adClas}>
                                                                                    <td style={{ fontSize: '12px', padding: '0.2rem' }}>{SatinAlmaNo}</td>
                                                                                    <td style={{ fontSize: '12px', padding: '0.2rem' }}>{Teklif.MustAdi}</td>
                                                                                    <td style={{ fontSize: '12px', padding: '0.2rem' }} > {Siparis.SiparisNo} </td>
                                                                                    <td style={{ fontSize: '12px', padding: '0.2rem' }}>{En}x{Boy}</td>
                                                                                    {RilVarMi == true && <td style={{ fontSize: '12px', padding: '0.2rem' }}>{Riller}</td>}
                                                                                    <td style={{ fontSize: '12px', padding: '0.2rem' }}>{DalgaTipiText}</td>
                                                                                    <td style={{ fontSize: '12px', padding: '0.2rem' }}>{TedarikciKalitesi}</td>
                                                                                    <td style={{ fontSize: '12px', padding: '0.2rem' }}>{BirimAgirlik.toFixed(2)}</td>
                                                                                    <td style={{ fontSize: '12px', padding: '0.2rem' }}>{ToplamAgirlik.toFixed(2)}</td>
                                                                                    <td style={{ fontSize: '12px', padding: '0.2rem' }}>{Adet}</td>
                                                                                    <td style={{ fontSize: '12px', padding: '0.2rem' }}>{BirimFiyat.toFixed(2)}₺</td>
                                                                                    <td style={{ fontSize: '12px', padding: '0.2rem' }}>{Tutar.toFixed(2)}₺</td>
                                                                                    {NotVarMi == true && <td style={{ fontSize: '12px', padding: '0.2rem' }}>{DegisiklikNotu}</td>}
                                                                                </tr>
                                                                            )
                                                                        }
                                                                        )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </Box>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                </Container>
                                            ))}
                                        </Accordion>
                                        }
                                    </Box>
                                    {(
                                        lst?.length == 0
                                        &&
                                        <div className='no-search'>
                                            <Divider space="2" />
                                            <img src={noSearchPng} alt="online kutu tasarımı" style={{ width: "200px", objectFit: "contain" }} />
                                            <div>Satın alma listesinde geçilen her hangi bir kayıt bulunamadı</div>


                                            <Divider space="5" />
                                        </div>
                                    )}

                                </Box>
                            </Flex>
                        </Box>
                    </Box>

                </form >
            }
        </Container>

    )
}

export default WithDefaultContent(SatinAlmaDetay)


